import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const ErrorModal = ({ message, isshow, onToggle }) => (
  <Modal className="modal-bg-white" isOpen={isshow} centered={true} toggle={onToggle}>
    <ModalBody className="modal-body pt-2 text-dark">
      <h4 className="text-danger my-4 font-40 text-center" id="modalBlacklistedLabel">
        Ooops!
      </h4>
      <p className="text-darkgray text-center">{message}</p>
      <div className="pt-3 text-center">
        <button type="button" className="btn px-5 mr-2" onClick={onToggle}>
          Cancel
        </button>
        <button type="button" className="btn bg-danger px-5 text-white" onClick={onToggle}>
          Ok
        </button>
      </div>
    </ModalBody>
  </Modal>
);

export default ErrorModal;
