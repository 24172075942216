import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import history from '../helpers/history';

class PageNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { url } = this.props.match;
    const showDescription = url.includes('/admin');
    if (showDescription) {
      return <Redirect from={url} to={`/?returnUrl=${encodeURIComponent(url)}`} />;
    }
    return (
      <div className="main-wrapper auth-wrapper d-flex justify-content-center align-items-center">
        <div className="auth-content animated fadeIn">
          <div className="card shadow-sm mb-0">
            <div className="card-body" style={{ textAlign: 'center' }}>
              <h1> 404 : Page Not found! </h1>

              <p
                className="c-pointer"
                onClick={() => {
                  history.push('/');
                }}
              >
                Back to Home
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps, null)(PageNotFound);
