const dev = {
  // api: {
  //   URL: "http://192.168.0.124:65534/api/v1"
  // },
  // CLIENT_ID: "someid",
  // CLIENT_SECRET: "somesecret",
  // CLIENT_DEVICE: "web"
  api: {
    URL: 'https://staging.veegs.shop/api/',
  },
  CLIENT_ID: 'tC4u0WcDPPpXxecc',
  CLIENT_SECRET: 'kiq9ZfDkjxtzmojZGGxs3jJxTsDA980P',
  CLIENT_DEVICE: 'web-admin',
};
const stage = {
  api: {
    URL: '/api/',
  },
  CLIENT_ID: 'tC4u0WcDPPpXxecc',
  CLIENT_SECRET: 'kiq9ZfDkjxtzmojZGGxs3jJxTsDA980P',
  CLIENT_DEVICE: 'web-admin',
};

const prod = {
  api: {
    URL: '/api/',
  },
  CLIENT_ID: 'VqbRUocoN5OCBHa3',
  CLIENT_SECRET: 'NpgDb5BBksSPq5z5fXyimjP3AThSBpic',
  CLIENT_DEVICE: 'web-admin',
};

let config;
switch (process.env.REACT_APP_STAGE) {
  case 'dev':
    config = dev;
    break;
  case 'stage':
    config = stage;
    break;
  case 'prod':
    config = prod;
    break;
  default:
    config = dev;
    break;
}

export default config;
