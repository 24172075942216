import history from '../../helpers/history';
import { showModal } from '../../redux/error/ErrorAction';
import { GET, POST } from '../../services/rest.service';
import { hideLoading } from '../loader/LoaderActions';

export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const LOGOUT = 'USERS_LOGOUT';
export const USER = 'USER';
export const UPDATE_USER = 'UPDATE_USER';

/* LOGIN */
export const login = (payload, returnUrl) => {
  return (dispatch) => {
    POST('/v1/auth/login', payload)
      .then(({ data }) => {
        localStorage.setItem('token', data.token);
        if (returnUrl) {
          console.log('returnUrl', decodeURIComponent(returnUrl));
        }
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            isLoggedIn: true,
            user: data,
            message: 'logged in successfully',
          },
        });
        if (returnUrl) {
          history.push(`${decodeURIComponent(returnUrl)}`);

          // console.log("returnUrl", decodeURIComponent(returnUrl));
        } else {
          history.push('/admin/dashboard');
        }
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAILURE,
          payload: {
            isLoggedIn: false,
            user: {},
            type: undefined,
            message: err.message,
          },
        });
        dispatch(hideLoading());
        dispatch(showModal(err.message));
        // toast.error(err.message);
      });
  };
};

export const getLoggedInUser = () => {
  return (dispatch) => {
    const url = `/v1/auth/me/`;
    GET(url)
      .then(({ data }) => {
        dispatch({
          type: USER,
          payload: {
            user: data,
            type: 'ADMIN',
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAILURE,
          payload: {
            user: {},
            type: undefined,
            isLoggedIn: false,
            message: err.message,
          },
        });
        localStorage.removeItem('token');

        history.push('/');
      });
  };
};
